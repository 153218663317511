import React from 'react'
import styled from 'styled-components'
import t from '../../translation'

const DownloadLinks = props => {
  const webApp = {
    link: 'https://webapp.solarforschools.co.uk',
    image: '/favicon-96x96.png'
  }
 
  return (
    <Container>
      <a href={t`links.app-store`}>
        <img src='/images/homepage/app-store.svg' alt='' />
      </a>
      <a href={t`links.play-store`}>
        <img src='/images/homepage/play-store.svg' alt='' />
      </a>
        <CustomLink text="Web App" image={webApp.image} link={webApp.link}  />
    </Container>
  )
}

const CustomLink = ({ text, image, link }) => {

  const styles = {
    layout: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "0.1rem solid rgb(0, 0, 0, 0.65)",
      borderRadius: "7px",
      padding: "0.25rem 0.65rem",
      marginBottom: "0.3rem"
    },
    reset: { textDecoration: "none" },
    text: { color: "rgb(0, 0, 0, 0.8)", fontWeight: "510", fontSize: "1.6rem" },
    image: { height: "2.5rem"}
  }

  return (
    <>
      <a href={link} style={styles.reset}>
        <div style={styles.layout}>
          <img src={image} style={styles.image} alt={`${text}-image`} />
          <div style={styles.text}>{text}</div>
        </div>
      </a>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  img {
    height: 60px;
    object-fit: contain;
    margin: 5px 0px;
    margin-right: 15px;
  }
`

export default DownloadLinks
